:root {
  /* colors */
  --primary-100: #e2e0ff;
  --primary-200: #c1beff;
  --primary-300: #a29dff;
  --primary-400: #837dff;
  --primary-500: #645cff;
  --primary-600: #504acc;
  --primary-700: #3c3799;
  --primary-800: #282566;
  --primary-900: #141233;

  /* grey */
  --grey-50: #f8fafc;
  --grey-100: #f1f5f9;
  --grey-200: #e2e8f0;
  --grey-300: #cbd5e1;
  --grey-400: #94a3b8;
  --grey-500: #64748b;
  --grey-600: #475569;
  --grey-700: #334155;
  --grey-800: #1e293b;
  --grey-900: #0f172a;
  /* rest of the colors */
  --black: #222;
  --white: #fff;
  --red-light: #f8d7da;
  --red-dark: #842029;
  --green-light: #d1e7dd;
  --green-dark: #0f5132;

  /* fonts  */
  --headingFont: 'Roboto', sans-serif;
  --bodyFont: 'Nunito', sans-serif;
  --smallText: 0.7em;
  /* rest of the vars */
  --backgroundColor: var(--grey-50);
  --textColor: var(--grey-900);
  --borderRadius: 0.25rem;
  --letterSpacing: 1px;
  --transition: 0.3s ease-in-out all;
  --max-width: 1120px;
  --fixed-width: 500px;
  --fluid-width: 90vw;

  /* box shadow*/
  --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);

  --gray: #F0F0F0;
  --accent-color: #635BFF;
  --radius: 3px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  white-space: pre-wrap;
}

.item {
  text-align:left;
}

.item:hover {
  background-color: var(--gray);
}

.item.selected {
  background-color: var(--grey-200);
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

/*
.item {
  background: var(--clr-white);
  display: flex;
  justify-content: space-between;
  max-width: var(--fixed-width);
  margin: 2rem auto;
  align-items: center;
  border-radius: var(--radius);
}

.item button,
.item a {
  background: transparent;
  border-color: transparent;
  color: var(--clr-primary-5);
  letter-spacing: var(--spacing);
  cursor: pointer;
}

.item {
  padding: 1rem 2rem;
}

.item h4 {
  margin-bottom: 0;
}

.item p {
  margin-bottom: 0;
}

.modal {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  background: var(--clr-white);
  display: inline-block;
  padding: 0.25rem 1rem;
  border-radius: var(--radius);
  text-transform: capitalize;
}

.modal p {
  margin-bottom: 0;
  color: var(--clr-red-dark);
}

.form {
  background: var(--clr-white);
  max-width: var(--fixed-width);
  margin: 0 auto;
  margin-bottom: 4rem;
  padding: 1rem 2rem;
  border-radius: var(--radius);
}

.form input {
  background: var(--clr-grey-10);
  border-color: transparent;
  border-radius: var(--radius);
  padding: 0.25rem 0.5rem;
}

.form-control {
  margin: 0.5rem 0;
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
}

.form button {
  display: inline-block;
  background: var(--clr-black);
  color: var(--clr-white);
  border-color: transparent;
  margin-top: 1rem;
  letter-spacing: var(--spacing);
  padding: 0.15rem 0.25rem;
  text-transform: capitalize;
  border-radius: var(--radius);
  cursor: pointer;
}
*/

* {
  box-sizing: border-box;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  text-align: center;
  /* background-color: #f1f1f1; */
}

.flex-item-left {
  padding: 10px;
  flex: 50%;
}

.flex-item-right {
  padding: 10px;
  flex: 50%;
}

/* Responsive layout - makes a one column-layout instead of a two-column layout */
@media (max-width: 800px) {

  .flex-item-right,
  .flex-item-left {
    flex: 100%;
  }
}

.register-container, .login-container { 
  font-size: 14px;
  text-align: left;
  margin: auto;
}



@media only screen and (max-width: 1280px) { /*large, laptops/desktops, */
  .register-container {
    width: 50%;
  }

  .login-container {
    width: 35%;
  }
}


@media only screen and (max-width: 1024px) { /*medium: tablets, */
  .register-container {
    width: 65%;
  }

  .login-container {
    width: 40%;
  }
}

@media only screen and (max-width: 768px) { /*small, Small devices: portable tablet and large phones*/

  .register-container {
    width: 80%;
  }

  .login-container {
    width: 60%;
  }
}

@media only screen and (max-width: 480px) {
  /*xs, Extra small devices: mobile phones*/

  .register-container{
      width: 90%;
  }

  .login-container {
    width: 70%;
  }
}

@media (min-width: 1281px) { /*extra large laptops/desktops*/
  .register-container {
    width: 50%;
  }

  .login-container {
    width: 20%;
  }
}



.page {
  min-height: calc(100vh - 6rem);
  width: var(--fluid-width);
  max-width: var(--max-width);
  margin: 0 auto;
  padding-top: 3rem;
}

/* alerts */
.alert {
  padding: 0.375rem 0.75rem;
  margin: 0 auto;
  border-color: transparent;
  border-radius: var(--borderRadius);
  width: var(--fluid-width);
  max-width: var(--fixed-width);
  text-align: center;
  text-transform: capitalize;
}

.alert-danger,.alert-success{
    margin-top: 0.5rem;
    text-align: center;
    font-size: 1rem;
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}

.alert-success {
  color: var(--green-dark);
  background: var(--green-light);
}

.normal-feedback{
    line-height: 300%;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
}

.instructions>svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}


/* stripe format*/
/* prices.html */
.price-list {
  display: flex;
}

.price-list>div {
  flex-grow: 3;
  margin: 0 10px;
  border: black solid 1px;
  padding: 20px;
}


button {
  background-color: var(--accent-color);
}

button {
  background: var(--accent-color);
  border-radius: var(--radius);
  color: white;
  border: 0;
  padding: 12px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}

button:hover {
  filter: contrast(115%);
}

button:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}

button:disabled {
  opacity: 0.5;
  cursor: none;
}

input,
select {
  display: block;
  font-size: 1.1em;
  width: 100%;
}

label {
  display: block;
}

a {
  color: var(--accent-color);
  font-weight: 900;
}

small {
  font-size: .6em;
}

fieldset,
input,
select {
  border: 1px solid #efefef;
}


#payment-form {
  border: #F6F9FC solid 1px;
  border-radius: var(--radius);
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
}

#messages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
  background-color: #0A253C;
  color: #00D924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size: 0.7em;
}